import { Nullable } from 'Shared/types'

export interface IExchangeService {
  getUnits(token: string, unitType: UnitType, term: string): Promise<string[]>
  getUnitDetails(token: string, unitType: UnitType, unit: string): Promise<MarketUnitInfoResponse>
  getExchangeRate(token: string, from: string, to: string): Promise<number>
  getEducatedGuess(token: string, unitType: UnitType, symbol: string): Promise<EducatedGuessResponse>
}

export enum UnitType {
  RegularCurrency = 'RegularCurrency',
  CryptoCurrency = 'CryptoCurrency',
  Stocks = 'Stocks'
}

export type MarketUnitInfoResponse = {
  symbol: string
  unitType: UnitType
  tradingCurrency: string
}

export type EducatedGuessResponse = {
  mostLikelySymbol: Nullable<string>
  otherSupportedSymbols: string[]
}
