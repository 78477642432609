export const GoogleTagManagerEvent = {
  login: 'login',
  logout: 'logout',
  onboarding: 'onboarding',
  subscriptionPlanSelect: 'subscription-plan-select',
  firstAssetCreated: 'first-asset-created',
  openedDemoAccountAsOnboarding: 'opened-demo-account-as-onboarding',
  bookDemoClick: 'book-demo-click',
  shareWithAdvisorClick: 'share-with-advisor-click',
}
