import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Stack, Tooltip } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { TextPrimary } from 'Shared/components/Typography'
import * as S from './styles'

interface Props {
  features: { key: string, hint?: string }[]
}

export const PricingCardSectionFeatures: React.FC<Props> = ({ features }) => {
  const { t } = useTranslation()

  return (
    <>
      {features.map(feature => (
        <S.FeatureListItem key={feature.key}>
          <S.FeatureListIcon />

          <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
            <TextPrimary><Trans i18nKey={feature.key} /></TextPrimary>

            {feature.hint && (
              <Tooltip title={t(feature.hint)} enterTouchDelay={0} leaveTouchDelay={5000}>
                <InfoOutlinedIcon fontSize="inherit" />
              </Tooltip>
            )}
          </Stack>
        </S.FeatureListItem>
      ))}
    </>
  )
}
