import { Stack, Typography, ListItem } from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'

import { styledWithCustomProp } from 'config/theme'
import { fontRubik, TextBig, TextPrimary } from 'Shared/components/Typography'

export const TierCard = styledWithCustomProp(Stack, 'highlighted')<{ highlighted: boolean }>(({ theme, highlighted }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  height: '100%',
  border: `1px solid ${theme.palette.blue.blue1}`,
  borderRadius: 12,
  ['button']: {
    backgroundColor: theme.palette.common.white,
  },

  ...(!highlighted && {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  }),

  ...(highlighted && {
    backgroundColor: theme.palette.blue.blue,
    color: theme.palette.common.white,
    ['button:hover']: {
      backgroundColor: theme.palette.blue.blue6,
      borderColor: theme.palette.common.white,
    },
  }),

  [theme.breakpoints.down('mobileLarge')]: {
    padding: theme.spacing(3),
  },
}))

export const PricingPlanText = styled(TextBig)(({ theme }) => ({
  fontFamily: fontRubik,
  fontWeight: 700,
  textTransform: 'uppercase',
})) as typeof Typography

export const PricingPlanSubText = styled(TextPrimary)(({ theme }) => ({
  fontFamily: fontRubik,
})) as typeof Typography

export const FeatureListIcon = styled(DoneOutlinedIcon)(({ theme }) => ({
  width: 18,
  height: 18,
  padding: theme.spacing(0.5),
  backgroundColor: alpha(theme.palette.dark.dark2!, 0.3),
  borderRadius: '25%'
}))

export const FeatureListItem = styled(ListItem)(({ theme }) => ({
  padding: theme.spacing(0.5, 0),
  gap: theme.spacing(1)
}))
