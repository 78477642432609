import React from 'react'
import { styled } from '@mui/system'
import { Typography, Stack } from '@mui/material'

interface Props {
  value: string
}

const splitTicker = (value: string) => {
  const [ left, right ] = value.split(':')

  if (left && right) {
    return [ left, right ]
  }

  return [ undefined, left || right || undefined ]
}

export const TickerContainer = styled(Typography)({
  display: 'inline',
  fontSize: 'inherit',
  fontWeight: 'inherit'
}) as typeof Typography

export const ExchangeContainer = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  display: 'inline',
  fontSize: '70%',
})) as typeof Typography

export const TickerName: React.FC<Props> = ({ value }) => {
  const [ exchange, ticker ] = React.useMemo(() => splitTicker(value), [ value ])

  return (
    <Stack direction="column">
      <TickerContainer component="span">{ticker}</TickerContainer>
      {exchange && <ExchangeContainer component="span">{exchange}</ExchangeContainer>}
    </Stack>
  )
}
