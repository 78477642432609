import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { IPlaidService, InstitutionConnection, AttachFinstrumentRequest } from './plaid-types'

export class PlaidService extends BaseService implements IPlaidService {
  public createLinkToken = (accessToken: string): Promise<string> => axios
    .post('/banking/plaid/link-token', {}, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)

  public createConnection = (accessToken: string, linkToken: string): Promise<string> => axios
    .post('/banking/plaid/connection', { payload: { publicToken: linkToken } }, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)

  public loadConnection = (accessToken: string, connectionKey: string): Promise<string[]> => axios
    .patch(`/banking/plaid/connection/${connectionKey}/load`, { }, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)

  public getConnection = (accessToken: string, connectionKey: string): Promise<InstitutionConnection> => axios
    .get(`/banking/plaid/connection/${connectionKey}`, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)

  public attachConnection = (accessToken: string, connectionKey: string, attachRequest: AttachFinstrumentRequest): Promise<void> => axios
    .patch(`/banking/plaid/connection/${connectionKey}/attach`, { payload: attachRequest }, { headers: { ...super.buildAuthHeader(accessToken) } })
    .then((response: AxiosResponse) => response?.data)
}
