import React from 'react'
import { List } from '@mui/material'

import { PricingPlan } from 'Pricing/pricing-types'
import { PricingCardSectionHeader } from './PricingCardSectionHeader'
import { PricingCardSectionPrice } from './PricingCardSectionPrice'
import { PricingCardSectionFeatures } from './PricingCardSectionFeatures'
import { PricingCardSectionActions } from './PricingCardSectionActions'
import * as S from './styles'

interface Props {
  title: string
  forWhomText: string
  choseButtonText: string
  features: { key: string, hint?: string }[]
  pricingPlan: PricingPlan
  onChosePlan?: () => void
  highlighted?: boolean
  disabled?: boolean
}

export const PricingCard: React.FC<Props> = ({
  title,
  forWhomText,
  choseButtonText,
  features,
  pricingPlan,
  onChosePlan,
  highlighted = false,
  disabled = false,
}) => (
  <S.TierCard highlighted={highlighted}>
    <PricingCardSectionHeader
      title={title}
      forWhomText={forWhomText}
      promoActive={pricingPlan.periodPromoActive}
    />

    <PricingCardSectionPrice pricingPlan={pricingPlan} />

    <List sx={{ flexGrow: 1 }}>
      <PricingCardSectionFeatures features={features} />
    </List>

    <PricingCardSectionActions
      onChosePlan={onChosePlan}
      disabled={!onChosePlan || disabled}
      ctaButtonText={choseButtonText}
    />
  </S.TierCard>
)
