import React from 'react'
import { Button } from '@mui/material'

interface Props {
  onChosePlan?: () => void
  ctaButtonText: string
  disabled: boolean
}

export const PricingCardSectionActions: React.FC<Props> = ({ ctaButtonText, onChosePlan, disabled }) => (
  <Button
    variant="outlined"
    onClick={onChosePlan}
    disabled={disabled}
    fullWidth
  >
    {ctaButtonText}
  </Button>
)
