import type { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { BaseService } from 'Shared/api'
import { IExchangeService, MarketUnitInfoResponse, UnitType, EducatedGuessResponse } from './exchangeService-types'

export class ExchangeService extends BaseService implements IExchangeService {
  public getUnits = (token: string, unitType: UnitType, term: string): Promise<string[]> => axios
    .get<string[]>(`exchange/reference/units/${unitType}?term=${term}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getUnitDetails = (token: string, unitType: UnitType, unit: string): Promise<MarketUnitInfoResponse> => axios
    .get<MarketUnitInfoResponse>(`exchange/reference/units/${unitType}/${unit}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getExchangeRate = (token: string, from: string, to: string): Promise<number> => axios
    .get<number>(`exchange/rates/${from}/${to}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)

  public getEducatedGuess = (token: string, unitType: UnitType, symbol: string): Promise<EducatedGuessResponse> => axios
    .get<EducatedGuessResponse>(`exchange/reference/educated-guess/${unitType}/${symbol}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
