import React, { useContext } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { Dialog, Stack, Box, Button } from '@mui/material'
import { range } from 'lodash'

import { SupportedLanguage } from 'config/i18n-types'
import { container, Service } from 'Services/container'
import { IProfileService } from 'Services/profile'
import {
  TextLarger,
  SubTextSecondary2,
  TextPrimary,
  ExternalLink,
} from 'Shared/components'
import { useAuthWithDemo } from 'Demo'
import { SettingsContext } from 'App/Settings'

interface Props {
  open: boolean
  onClose: () => void
  allowClickOutside: boolean
}

const ReleaseNotesEn = 'https://share.nuclino.com/p/FIREkit-Changelog--dp2gOz-91T4BGZuiU_Wto'
const ReleaseNotesUk = 'https://share.nuclino.com/p/FIREkit--kt2EDpJesgpPvOPPSbZLoH'
const NumberOfUpdates = 7
const ReleaseVersion = 'v3.15'

export const ReleaseNotesDialog: React.FC<Props> = ({ open, onClose, allowClickOutside }) => {
  const { t, i18n } = useTranslation()
  const { manageSettings: { releaseNotesDialog } } = useContext(SettingsContext)
  const { getAccessTokenWithDemo } = useAuthWithDemo()

  const releaseNotesLink = i18n.resolvedLanguage === SupportedLanguage.UK ?
    ReleaseNotesUk :
    ReleaseNotesEn

  const notifyAboutReadReleaseNotes = async () => {
    const portfolioService = container.resolve<IProfileService>(Service.ProfileService)
    const token = await getAccessTokenWithDemo()
    await portfolioService.readReleaseNotes(token)
  }

  const handleAgree = async () => {
    await notifyAboutReadReleaseNotes()
    releaseNotesDialog.readReleaseNotes()
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={() => allowClickOutside && onClose()}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
    >
      <Stack p={4} spacing={4}>
        <Box>
          <TextLarger textAlign="center" fontWeight={700}>
            {t('releaseNotes.title', { ns: 'notification' })}
          </TextLarger>
          <SubTextSecondary2 textAlign="center">{ReleaseVersion}</SubTextSecondary2>
        </Box>

        <Stack spacing={1}>
          {range(NumberOfUpdates).map((_, idx) => (
            <TextPrimary key={idx}>
              •&nbsp;
              <Trans
                i18nKey={`releaseNotes.update${idx + 1}`}
                ns="notification"
              />
            </TextPrimary>
          ))}
        </Stack>

        <Stack spacing={1}>
          <Button variant="contained" onClick={handleAgree}>
            {t('common.actions.agree')}
          </Button>
          <ExternalLink href={releaseNotesLink} sx={{ textAlign: 'center', fontSize: 'small' }}>
            {t('common.actions.moreDetails')}
          </ExternalLink>
        </Stack>
      </Stack>
    </Dialog>
  )
}
