import React from 'react'
import { Stack } from '@mui/material'

import { PromoCode } from './PromoCode'
import * as S from './styles'
import { PricingPlanSubText } from './styles'

interface Props {
  title: string
  forWhomText: string
  promoActive?: boolean
}

export const PricingCardSectionHeader: React.FC<Props> = ({ title, promoActive, forWhomText }) => (
  <Stack>
    <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
      <S.PricingPlanText>{title}</S.PricingPlanText>
      {promoActive && <PromoCode />}
    </Stack>
    <S.PricingPlanSubText>{forWhomText}</S.PricingPlanSubText>
  </Stack>
)
