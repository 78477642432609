import { Nullable } from 'Shared/types'

export interface IPlaidService {
  createLinkToken: (accessToken: string) => Promise<string>
  createConnection: (accessToken: string, linkToken: string) => Promise<string>
  loadConnection: (accessToken: string, connectionKey: string) => Promise<string[]>
  getConnection: (accessToken: string, connectionKey: string) => Promise<InstitutionConnection>
  attachConnection: (accessToken: string, connectionKey: string, attachRequest: AttachFinstrumentRequest) => Promise<void>
}

export type InstitutionAccount = {
  finstrumentKey: Nullable<string>
  key: string
  name: string
}

export type InstitutionConnection = {
  key: string
  institutionName: string
  accounts: InstitutionAccount[]
}

export type AttachFinstrumentRequest = {
  accountKey: string
  finstrumentKey: string
}

export enum PlaidAccountType {
  Investment = 'investment',
}
