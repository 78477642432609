import { Role, RoleName, Permission } from './authorization-types'

export const UserWithPaymentIssues: Role = {
  name: RoleName.UserWithPaymentIssues,
  permissions: [ ],
}

export const User: Role = {
  name: RoleName.User,
  permissions: [
    Permission.ViewSubscribeButton,
    Permission.CreateConnectionSnapshot,
  ],
}

export const PremiumUser: Role = {
  name: RoleName.PremiumUser,
  permissions: [
    Permission.ViewPremiumBadge,
    Permission.ViewActionsHistory,
    Permission.ViewAdvancedCharts,
    Permission.AddUnlimitedAssets,
    Permission.ConnectAssets,
  ],
}

export const FinancialAdvisorClient: Role = {
  name: RoleName.FinancialAdvisorClient,
  permissions: [
    Permission.ViewPremiumBadge,
    Permission.ViewActionsHistory,
    Permission.ViewAdvancedCharts,
    Permission.ViewMyTasks,
    Permission.AddUnlimitedAssets,
    Permission.ConnectAssets,
  ],
}

export const OrganizationAdvisor: Role = {
  name: RoleName.OrganizationAdvisor,
  permissions: [
    Permission.ViewAdvisorPortal,
    Permission.ViewActionsHistory,
    Permission.ViewAdvancedCharts,
    Permission.AddUnlimitedAssets,
    Permission.ConnectAssets,
  ]
}

export const OrganizationOwner: Role = {
  name: RoleName.OrganizationOwner,
  permissions: [
    Permission.ViewAdvisorPortal,
    Permission.ViewActionsHistory,
    Permission.ViewAdvancedCharts,
    Permission.ViewOrganizationClients,
    Permission.AddUnlimitedAssets,
    Permission.ConnectAssets,
  ],
}
