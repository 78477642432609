import { AxiosResponse } from 'axios'

import { default as axios } from 'config/axios'
import { ProfileService } from 'Services/profile/profile-service'
import { IProfileService } from './profile-types'

export class ProfileSharedService extends ProfileService implements IProfileService {
  private readonly sharingKey: string

  public constructor(sharingKey: string) {
    super()
    this.sharingKey = sharingKey
  }

  public exportAiProfile = (token: string, holdingKeys: string[]) =>
    this.exportSharedAiProfile(token, this.sharingKey, holdingKeys)

  private exportSharedAiProfile = (token: string, sharingKey: string, holdingKeys: string[]) => axios
    .get(`/profile/shared-with-me/${sharingKey}/ai/export${this.buildQueryString(holdingKeys)}`, { headers: { ...super.buildAuthHeader(token) } })
    .then((response: AxiosResponse) => response?.data)
}
