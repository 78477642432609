import { GridRowId } from '@mui/x-data-grid-pro'
import { Holding, Summary } from 'Services/holdings'
import type { IAction, IActionWithToken, ITypedAction, ITypedActionWithToken } from 'Shared/types'
import { HoldingsActionTypes } from './holdings-constants'
import {
  HoldingsFilterState,
  PinPayload,
  OperationType,
  TransferDrawerState,
  BuySellDrawerState,
  ChangeTickerDrawerState,
  AttachFinstrumentPayload
} from './holdings-types'

const getHoldings = (token: string): IActionWithToken => ({
  type: HoldingsActionTypes.GET_HOLDINGS,
  token
})

const getHoldingsSuccess = (payload: Holding[]): ITypedAction<Holding[]> => ({
  type: HoldingsActionTypes.GET_HOLDINGS_SUCCESS,
  payload
})

const getHoldingsFailure = (): IAction => ({
  type: HoldingsActionTypes.GET_HOLDINGS_FAILURE,
})

const createHolding = (payload: Holding): ITypedAction<Holding> => ({
  type: HoldingsActionTypes.CREATE_HOLDING,
  payload
})

const archiveHolding = (key: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.ARCHIVE_HOLDING,
  payload: key,
  token
})

const deleteHolding = (key: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.DELETE_HOLDING,
  payload: key,
  token
})

const getSummary = (token: string): IActionWithToken => ({
  type: HoldingsActionTypes.GET_SUMMARY,
  token
})

const getSummarySuccess = (payload: Summary): ITypedAction<Summary> => ({
  type: HoldingsActionTypes.GET_SUMMARY_SUCCESS,
  payload
})

const getSummaryFailure = (): IAction => ({
  type: HoldingsActionTypes.GET_SUMMARY_FAILURE,
})

const togglePinHolding = (key: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.TOGGLE_PIN_HOLDING,
  payload: key,
  token
})

const pinHoldingSuccess = (payload: PinPayload): ITypedAction<PinPayload> => ({
  type: HoldingsActionTypes.PIN_HOLDING_SUCCESS,
  payload
})

const setSelectedHoldings = (payload: GridRowId[]): ITypedAction<GridRowId[]> => ({
  type: HoldingsActionTypes.SET_SELECTED_HOLDINGS,
  payload
})

const resetState = (): IAction => ({
  type: HoldingsActionTypes.RESET_STATE_HOLDINGS
})

const changeFilter = (filterState: HoldingsFilterState): ITypedAction<HoldingsFilterState> => ({
  type: HoldingsActionTypes.SET_HOLDINGS_FILTER_CHANGED,
  payload: filterState
})

const openCreateHoldingDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.OPEN_CREATE_HOLDING_DRAWER
})

const closeCreateHoldingDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_CREATE_HOLDING_DRAWER
})

const openViewHoldingDrawer = (holdingKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_VIEW_HOLDING_DRAWER,
  payload: holdingKey
})

const closeViewHoldingDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_VIEW_HOLDING_DRAWER
})

const openEditHoldingDrawer = (holdingKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_EDIT_HOLDING_DRAWER,
  payload: holdingKey
})

const closeEditHoldingDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_EDIT_HOLDING_DRAWER
})

const openDeleteHoldingModal = (holdingKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_DELETE_HOLDING_MODAL,
  payload: holdingKey
})

const closeDeleteHoldingModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_DELETE_HOLDING_MODAL
})

const openArchiveHoldingModal = (holdingKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_ARCHIVE_HOLDING_MODAL,
  payload: holdingKey
})

const closeArchiveHoldingModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_ARCHIVE_HOLDING_MODAL
})

const openDepositWithdrawDrawer = (holdingKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_DEPOSIT_WITHDRAW_DRAWER,
  payload: holdingKey
})

const closeDepositWithdrawDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_DEPOSIT_WITHDRAW_DRAWER
})

const openFixProfitLossDrawer = (holdingKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_FIX_PROFIT_LOSS_DRAWER,
  payload: holdingKey
})

const closeFixProfitLossDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_FIX_PROFIT_LOSS_DRAWER
})

const openTransferDrawer = (holdingKey: string, fromTicker?: string): ITypedAction<TransferDrawerState> => ({
  type: HoldingsActionTypes.drawers.OPEN_TRANSFER_DRAWER,
  payload: { holdingKey, fromTicker }
})

const closeTransferDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_TRANSFER_DRAWER
})

const openBuySellDrawer = (holdingKey: string, ticker?: string, operationType?: OperationType): ITypedAction<BuySellDrawerState> => ({
  type: HoldingsActionTypes.drawers.OPEN_BUY_SELL_DRAWER,
  payload: { holdingKey, ticker, operationType }
})

const closeBuySellDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_BUY_SELL_DRAWER
})

const openChangeTickerDrawer = (holdingKey: string, ticker: string): ITypedAction<ChangeTickerDrawerState> => ({
  type: HoldingsActionTypes.drawers.OPEN_CHANGE_TICKER_DRAWER,
  payload: { holdingKey, ticker }
})

const closeChangeTickerDrawer = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_CHANGE_TICKER_DRAWER
})

const openImportHoldingsModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.OPEN_IMPORT_HOLDINGS_MODAL
})

const closeImportHoldingsModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_IMPORT_HOLDINGS_MODAL
})

const openConnectAccountModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.OPEN_CONNECT_ACCOUNT_MODAL
})

const closeConnectAccountModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_CONNECT_ACCOUNT_MODAL
})

const openAttachAccountModal = (finstrumentKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_ATTACH_ACCOUNT_MODAL,
  payload: finstrumentKey
})

const closeAttachAccount = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_ATTACH_ACCOUNT_MODAL
})

const openDisconnectAccountModal = (holdingKey: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_DISCONNECT_ACCOUNT_MODAL,
  payload: holdingKey
})

const closeDisconnectAccountModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_DISCONNECT_ACCOUNT_MODAL
})

const openShareToChatbotModal = (chatbotText: string): ITypedAction<string> => ({
  type: HoldingsActionTypes.drawers.OPEN_SHARE_TO_CHATBOT_MODAL,
  payload: chatbotText
})

const closeShareToChatbotModal = (): IAction => ({
  type: HoldingsActionTypes.drawers.CLOSE_SHARE_TO_CHATBOT_MODAL
})

const setBackdrop = (enabled: boolean): ITypedAction<boolean> => ({
  type: HoldingsActionTypes.SET_BACKDROP,
  payload: enabled
})

const disconnectHolding = (holdingKey: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.DISCONNECT_HOLDING,
  payload: holdingKey,
  token
})

const refreshHolding = (holdingKey: string, token: string): ITypedActionWithToken<string> => ({
  type: HoldingsActionTypes.REFRESH_HOLDING,
  payload: holdingKey,
  token
})

export const HoldingActions = {
  getHoldings,
  getHoldingsSuccess,
  getHoldingsFailure,
  createHolding,
  archiveHolding,
  deleteHolding,
  getSummary,
  getSummarySuccess,
  getSummaryFailure,
  togglePinHolding,
  pinHoldingSuccess,
  setSelectedHoldings,
  resetState,
  changeFilter,
  drawers: {
    viewHolding: {
      open: openViewHoldingDrawer,
      close: closeViewHoldingDrawer
    },
    createHolding: {
      open: openCreateHoldingDrawer,
      close: closeCreateHoldingDrawer
    },
    editHolding: {
      open: openEditHoldingDrawer,
      close: closeEditHoldingDrawer
    },
    deleteHolding: {
      open: openDeleteHoldingModal,
      close: closeDeleteHoldingModal
    },
    archiveHolding: {
      open: openArchiveHoldingModal,
      close: closeArchiveHoldingModal
    },
    depositWithdraw: {
      open: openDepositWithdrawDrawer,
      close: closeDepositWithdrawDrawer
    },
    fixProfitLoss: {
      open: openFixProfitLossDrawer,
      close: closeFixProfitLossDrawer
    },
    transfer: {
      open: openTransferDrawer,
      close: closeTransferDrawer
    },
    buySell: {
      open: openBuySellDrawer,
      close: closeBuySellDrawer
    },
    changeTicker: {
      open: openChangeTickerDrawer,
      close: closeChangeTickerDrawer
    },
    importHoldings: {
      open: openImportHoldingsModal,
      close: closeImportHoldingsModal
    },
    connectAccount: {
      open: openConnectAccountModal,
      close: closeConnectAccountModal
    },
    attachAccount: {
      open: openAttachAccountModal,
      close: closeAttachAccount
    },
    disconnectAccount: {
      open: openDisconnectAccountModal,
      close: closeDisconnectAccountModal
    },
    shareToChatbot: {
      open: openShareToChatbotModal,
      close: closeShareToChatbotModal
    }
  },
  setBackdrop,
  disconnectHolding,
  refreshHolding,
}
